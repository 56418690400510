.navbar-container {
    width: 100%;
    height: 67px;
    /* background-color: aqua; */
    /* padding: 1rem; */
    margin: 0;
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 2px #a0a0a0;
    z-index: 1000;
}

.navbar-logo {
    height: 67px;
    width: 150px;
    object-fit: cover;
}

.flexbox {
    flex: 1;
}

.items {
    display: flex;
    flex-direction: row;
}

.item-container {
    padding: 1.5rem;
    transition: all ease 0.10s;
    color: #3A80D1;
    font-family: ubuntu-medium;
}

.home:hover, .inventory:hover, .about-us:hover, .contact-us:hover {
    cursor: pointer;
    color: #21538b;
    border-bottom: #275284 solid 3px;
}