.car-card-container {
    height: 31.875rem; /* or fit-content */
    width: 25rem;
    background-color: #ffffff;
    border-radius: 0.625rem;
    padding: 1.5rem;
    margin: 0 auto 1rem auto;
    box-shadow: 0px 0px 2px #a0a0a0;
    transform: scale(87.5%);
}

.car-img {
    border-radius: 1rem;
    width: 100%;
    height: 9.5rem;
    object-fit: cover;
    object-position: 0 65%;
}

.car-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    margin-top: 1.3rem;
    justify-content: space-between;
}

.car-model {
    text-align: start;
    font-size: 1.5rem;
    margin: auto 0;
    color: #30416D;
    font-family: nunito-semibold;
}

.availability {
    font-size: 1rem;
    margin: auto 0;
    text-align: center;
    padding: 0.25rem;
    border-radius: 0.625rem;
    width: 33%;
    height: 100%;
    color: #FFFAFA;
    font-family: nunito-bold;
}

.available {
    background-color: #84D47D;
}

.unavailable {
    background-color: #d47d7d;
}

.price-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    padding: 0 2.5rem;
    margin: 1rem 0;
    color: #30416D;
}

.dealer-price {
    font-size: 1.2rem;
    margin: auto 0;
    font-family: nunito-semibold;
}

.price-of-car {
    font-size: 1.75rem;
    margin: auto 0;
    font-family: nunito-medium;
}

.car-info {
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin: 0.5rem 1.5rem 2rem 1.5rem;
    padding: 0.95rem;
    background-color: #EAE6E6;
    color: #30416D;
    border-radius: 0.625rem;
    justify-content: space-between;
    font-family: ubuntu-medium;
}

.car-info-heading {
    font-size: 0.55rem;
}

.car-info-content {
    font-size: 1.2rem;
    margin-left: 0.5rem;
}

.details-container {
    width: 100%;
    padding: 0 1.5rem;
}

.additional-details {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    color: #ffffff;
    background-color: #3f587d;
    opacity: 60%;
    padding: 1rem 1.5rem;
    border: none;
    transition: all ease 0.15s;
    border-radius: 0.625rem;
    width: 100%;
    cursor: pointer;
}

.detail-text {
    font-family: ubuntu-bold;
    font-size: 1.125rem;
    margin: auto 0;
}

.detail-arrow {
    font-size: 1rem;
    margin: auto 0 auto auto;
}

.additional-details:hover {
    opacity: 75%;
    transition: all ease 0.25s;
}

.additional-details:active {
    color: #3f587d;
    background-color: #cad6e7;
    opacity: 100%;
    transition: all ease 0.2s;
}