.slider-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slider {
    position: absolute;
    width: fit-content;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
}

.slide-cards {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.current-card {
    transition: all ease 1.5s;
}