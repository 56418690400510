.app {
  /* padding: 2rem; */
  position: fixed;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.filler {
  background-color: grey;
  width: 100%;
  position: absolute;
  height: 16%;
  bottom: 0;
}

#opaque-div {
  position: absolute;
  padding: 4rem;
  text-align: center;
  bottom: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main-div {
  position: absolute;
  /* top: 15%;
  left: 5%; */
  z-index: 0;
  margin: auto;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.updating-soon {
  /* position: absolute; */
  /* top: 15%; */
  /* right: 5%; */
  /* margin: auto; */
  /* margin-top: 10rem; */
  color: #b83e3e;
  margin: 1rem;
  font-size: 3rem;
  font-family: ubuntu-bold;
}

.info-person {
  color: #3a80d1;
  font-family: ubuntu-medium;
  font-size: 3rem;
}

.info-text {
  color: #c38f1e;
  font-family: ubuntu-medium;
  font-size: 2rem;
  /* margin-bottom: 2rem; */
}

.info-text.address {
  color: #3a80d1;
  padding: 0.5rem;
  font-size: 2rem;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 75% 0%;
} 

.welcome {
  color: #3a80d1;
  font-size: 3.75rem;
  font-family: ubuntu-bold;
  border-bottom: rgb(10, 7, 62) solid 1px;
  padding: 0.5rem;
}

@font-face {
  font-family: ubuntu-medium;
  src: url(../fonts/Ubuntu-Medium.ttf);
}

@font-face {
  font-family: ubuntu-bold;
  src: url(../fonts/Ubuntu-Bold.ttf);
}

@font-face {
  font-family: nunito-semibold;
  src: url(../fonts/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: nunito-medium;
  src: url(../fonts/Nunito-Medium.ttf);
}

@font-face {
  font-family: nunito-bold;
  src: url(../fonts/Nunito-Bold.ttf);
}

@media screen and (max-width: 1100px) {

  .app {
    position: absolute;
  }

  #opaque-div {
    display: inherit;
    bottom: auto;
  }

  .info-text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .main-div {
    margin-top: 2rem;
  }

  .info-text.info-text.address {
    font-size: 1.5rem;
  }

  .updating-soon {
    font-size: 2rem;
  }

  .welcome {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 550px) {
  .main-div {
    width: 100%;
    padding: 2rem;
  }

  #opaque-div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}